<template>
  <p class="clearfix mb-0 div-footer" >
    <span class="float-md-left d-block d-md-inline-block mt-2">
      <b-link
        class="ml-25"
        href="https//ninhbinh.vnpt.vn"
        target="_blank"
      >{{ appBrandName }}</b-link>
      © 2022 - {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block position-relative">
      <b class="lable-app">IOS</b>
      <b-link class="url-app" href="https://apps.apple.com/vn/app/id6711345740?platform=iphone" target="blank">
        <b-img
          :src="require('@/assets/images/pages/vnpt_qrcode_ios_bachnghe.png')"
          height="auto"
          width="12"
          class="img-fluid ios-qr"
          alt="IOS QR"
        />
      </b-link>
      <b class="lable-app">Android</b>
      <b-link class="url-app" href="https://play.google.com/store/apps/details?id=com.vnpt.uni.bachnghe" target="blank">
        <b-img
          :src="require('@/assets/images/pages/vnpt_qrcode_android_bachnghe.png')"
          height="auto"
          width="12"
          class="img-fluid android-qr"
          alt="Android QR"
        />
      </b-link>
      <b-img
          height="35"
          :src="imgSrc"
          alt="logo"
      />
    </span>
  </p>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      appBrandName: process.env.VUE_APP_BRAND_NAME,
    }
  },
  computed: {
    imgSrc() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/images/logo/${process.env.VUE_APP_LOGO_FOOTER}`)
    },
  },
}
</script>
<style lang="scss">
  .lable-app {
    margin-right: 3px;
  }

  .footer {
    height: 4.65rem !important;
    padding: 0rem 2rem !important;
  }

  .ios-qr {
    width: 60px; margin-right: 25px;
  }

  .android-qr {
    width: 60px; margin-right: 30px;
  }
</style>
